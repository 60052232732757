@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	margin: 0;
	border: 0;
	padding: 0;
	background: #fafafa;
}
